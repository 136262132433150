//import boilerplate imports for react
import React from 'react';
import { Container , Col, Row } from 'react-bootstrap';
import './Timer.css';
import CarAnimation from './CarAnimation.js';
import Camera from './Camera';
import play from "./assets/play.png";
import pause from "./assets/pause.png";
import tomato_dark from "./assets/tomato_dark.png";
import tomato_light from "./assets/tomato_light.png";
import replay from "./assets/replay.png";
import plus from "./assets/plus.svg";
import minus from "./assets/minus.svg"



function Timer(props) {

    const bike_bell_path = require('./assets/mixkit-bike-bell-ring-595.mp3')
    const bike_bell = new Audio(bike_bell_path)

    const numMinutes = React.useRef(5)
    const [timeRemaining, setTimeRemaining] = React.useState(0);
    const [animationTime , setAnimationTime] = React.useState(0);
    const [isPomodoro , setIsPomodoro] = React.useState(false);
    const [isRunning, setIsRunning] = React.useState(false);
    const breakTime = props.breakTime * 60;
    // const [onBreak, setOnBreak] = React.useState(false);
    const [sessionTime, setSessionTime] = React.useState(0);
    // userPause is used to keep track of whether pause was triggered by user leaving (so it needs to auto start when they come )
    // or by the the user so no need to autostart/stop
    //switched userPause and onBreak to userRef as they do not require a re-render wheras the other ones change the dom. ex: pomodoro button changes
    const userPause = React.useRef(false)
    const onBreak = React.useRef(false)
    const [count , setCount] = React.useState(0)

    React.useEffect(() => {
     if (isRunning === true && timeRemaining > 0) {
        const interval = setInterval(() => {
            setTimeRemaining(timeRemaining - 1);
            console.log("current pomodoro count:", count)
        }, 1000); //1s = 1000ms
        return () => {
            clearInterval(interval);
        };
      }
    if (isRunning === false && timeRemaining > 0) {
        setAnimationTime(timeRemaining);
    }
    if(timeRemaining === 0){
        if(isPomodoro === true){
            if(onBreak.current === false){
                bike_bell.play()
                setTimeRemaining(breakTime);
                setAnimationTime(breakTime );
                // setOnBreak(true);
                // setUserPause(true)
                onBreak.current = true
                userPause.current = true

            }else{
                setTimeRemaining(sessionTime);
                setAnimationTime(sessionTime);
                setCount(count + 1)
                // setOnBreak(false);
                onBreak.current = false
                userPause.current = true
                bike_bell.play()
            }
        }
        else{
            setIsRunning(false);
            // setOnBreak(false);
            onBreak.current = false
            setTimeRemaining(0);
            setAnimationTime(0);
            setCount(0)
            userPause.current = false
        }
    
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }}, [isRunning, timeRemaining]);

    const handleCameraEvent = (event) => {

        if(onBreak.current === false){
            if(userPause.current === true ){
                setIsRunning(event)
            }
        }
    }

    


  return (
    <Container className="completeTimerRow ">
        <Row className='pomodoroCounterRow'>
        <Col>
        </Col >
        <Col className='justify-content-center'>
            {/* {isPomodoro === true ? (<p className='pomodoroCount'>{count}</p>) : (<p className='pomodoroCount'></p>)}  */}
            <p className='pomodoroCount' style={{ visibility: isPomodoro ? 'visible' : 'hidden' }}>{count}</p>
        </Col>
        <Col className='settings'>
        </Col>
        </Row>
        <Row className='animationRow'>
            <Col xs={1} className='buttonColmn'>
                    <button className="button_symbol_right" onClick={() =>{
                    if(timeRemaining>0){
                        if(isRunning === false && userPause.current === false){
                            setTimeRemaining(timeRemaining  - (numMinutes.current * 60) );
                            setAnimationTime(timeRemaining   - (numMinutes.current * 60) );
                            setSessionTime(timeRemaining - (numMinutes.current * 60));
                        }}
                    }}>
                     <img src={minus} className="add_sub_time" alt="increase time"></img>
                    </button>
            </Col>
            <Col className='animationColumn' >
                <Row >
                    <CarAnimation totalTime={animationTime} isRunning={isRunning} timeRemaining={timeRemaining} onBreak={onBreak.current} className="animation"></CarAnimation>
                </Row>
                <Row>
                   
                    <Col>
                        <button className="playPause" onClick={()=> 
                            {
                                setIsPomodoro(false);
                                onBreak.current = false
                                setTimeRemaining(0);
                                setAnimationTime(0);
                                setIsRunning(false);
                                userPause.current = false
                                setSessionTime(0);
                            }
                        }>
                        <img src={replay} className="playPauseIcon" alt='reset button'></img>
                        </button>
                    </Col>
                    <Col>
                        <button onClick={() => {
                                                    userPause.current = (!userPause.current)
                                                    setIsRunning(!isRunning)
                                                }
                                                } className="playPause">
                        {isRunning ? <img src={pause} className="playPauseIcon" alt='pause button'></img> : <img src={play}  className="playPauseIcon" alt='play button'></img>}
                        </button>
                    </Col>
                    <Col>
                        <button className="playPause" onClick={()=> setIsPomodoro(!isPomodoro)}>
                        {isPomodoro ? <img src={tomato_dark} className="playPauseIcon" alt='pomodoro button on'></img> : <img src={tomato_light}  className="playPauseIcon"  alt='pomodoro button off'></img>}
                        </button>
                    </Col>
    
                 </Row>

            </Col>
            <Col xs={1} className='buttonColmn'>
                <button className="button_symbol_left" onClick={() => {
                                                                if (isRunning === false && userPause.current === false){
                                                                    setTimeRemaining(timeRemaining + (numMinutes.current * 60));
                                                                    setAnimationTime(timeRemaining   + (numMinutes.current * 60))
                                                                    setSessionTime(timeRemaining+ (numMinutes.current * 60))
                                                                    }
                                                                }
                                                            }>
                                                                   <img src={plus} className="add_sub_time" alt="increase time"></img>
                </button>
            </Col>
        </Row>
        <Row>
            <Camera activateCamera={props.activateCamera} handleCameraEvent={handleCameraEvent} ></Camera>
        </Row>

    </Container>
    );
}

export default Timer;
